import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Header from '../../components/Header';
import Spinner from '../../assets/svgs/spinner';

import { states, formatarTelefone, formatarCep } from '../../utils';
import { loginUserSuccess } from '../../redux/actions';
import { HelpersAdress, HelpersAuth } from '../../helpers';

const helpersAdress = new HelpersAdress();
const helpersAuth = new HelpersAuth();

const Adress = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const user = useSelector(state => state.Auth.user);
   const [citys, setCitys] = useState([]);
   const [loading, setLoading] = useState(false);

   const AddressSchema = Yup.object().shape({
      cep: Yup.string()
         .matches(/^\d{8}$/, "CEP inválido")
         .required("O CEP é obrigatório"),
      street: Yup.string()
         .required("A rua é obrigatória"),
      state: Yup.string()
         .required("O estado é obrigatório"),
      city: Yup.string()
         .required("A cidade é obrigatória"),
      district: Yup.string()
         .required("O bairro é obrigatório"),
      complement: Yup.string()
         .notRequired(), // Opcional, não é obrigatório
      number: Yup.string()
         .required("O número é obrigatório"),
      phoneNumber: Yup.string()
         .required("O telefone é obrigatório")
         .matches(/^\d{10,11}$/, "Telefone inválido"),
   });


   const formik = useFormik({
      validationSchema: AddressSchema,
      validateOnBlur: false,
      validateOnChange: true,
      initialValues: {
         cep: '',
         street: '',
         state: '',
         city: '',
         district: '',
         complement: '',
         number: '',
         phoneNumber: '',
      },
      onSubmit: async values => {
         setLoading(true)
         const usuario = {
            id: user.id,
            profile_id: 3,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            phone: values.phoneNumber.replace('(', '').replace(')', '').replace('-', '').trim(),
            city_id: values.city,
            state_id: values.state,
            cep: values.cep.replace('-', '').trim(),
            street: values.street.trim(),
            number: values.number,
            district: values.district.trim(),
            complement: values.complement.trim()
         }
         await helpersAuth.UpdateUser(usuario)
         .then(async () => {
            const validToken = localStorage.getItem("token_cliente")
            const user = await helpersAuth.GetUser(validToken);
            dispatch(loginUserSuccess(user.data.data))
            navigate('/checkout')
            setLoading(false)
         }).catch((e) => {
            console.log(e)
         })
      },
   });
   
   const findCity = async (sta) => {
      try {
         const resp = await helpersAdress.ListCities(sta);
         setCitys(resp.data.data);
      } catch (error) {
         console.log('Failed to fetch cities:', error);
      }
   };

   return (
      <>
         <Header title={'Endereço'} />
         <div className='bg-white max-w-6xl sm:py-20 p-5 mx-auto block'>
            <form className="space-y-6" onSubmit={formik.handleSubmit}>
               <div className="f">
                  <div className="space-y-2">
                     <p className='mb-4 text-base font-semibold text-gray-900'>Endereço de entrega:</p>
                     <div>
                        <input
                           type="text"
                           name="cep"
                           id="cep"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                           p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite seu CEP"
                           maxLength={9}
                           value={formatarCep(formik.values.cep)}
                           onChange={(e) => formik.setFieldValue('cep', e.target.value.replace(/\D/g, ''))}
                           onBlur={formik.handleBlur}
                        />
                        {formik.touched.cep && formik.errors.cep ? <p className='text-red-500 text-xs italic'>{formik.errors.cep}</p> : null}
                     </div>

                     <div>
                        <input
                           type="text"
                           name="street"
                           id="street"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                           p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite sua rua"
                           value={formik.values.street}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                        />
                        {formik.touched.street && formik.errors.street ? <p className='text-red-500 text-xs italic'>{formik.errors.street}</p> : null}
                     </div>

                     <div>
                        <select
                           name="state"
                           id="state"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                        p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           value={formik.values.state}
                           onChange={(e) => {
                              formik.setFieldValue('state', e.target.value)
                              formik.setFieldValue('city', '')
                              findCity(e.target.value)
                           }}
                           onBlur={formik.handleBlur}
                        >
                           <option value="">Selecione o estado</option>
                           {states.map((item, index) => (
                              <option value={item.id} key={index.toString()}>{item.name}</option>
                           ))}
                        </select>
                        {formik.touched.state && formik.errors.state ? <p className='text-red-500 text-xs italic'>{formik.errors.state}</p> : null}
                     </div>

                     <div>
                        <select
                           name="city"
                           id="city"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                           p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           value={formik.values.city}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                        >
                           <option value="">Selecione a cidade</option>
                           {citys.map((item, index) => (
                              <option value={item.id} key={index.toString()}>{item.name}</option>
                           ))}
                        </select>
                        {formik.touched.city && formik.errors.city ? <p className='text-red-500 text-xs italic'>{formik.errors.city}</p> : null}
                     </div>

                     <div>
                        <input
                           type="text"
                           name="district"
                           id="district"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                           p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite seu bairro"
                           value={formik.values.district}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                        />
                        {formik.touched.district && formik.errors.district ? <p className='text-red-500 text-xs italic'>{formik.errors.district}</p> : null}
                     </div>

                     <div>
                        <input
                           type="text"
                           name="complement"
                           id="complement"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                           p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Complemento"
                           value={formik.values.complement}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                        />
                        {formik.touched.complement && formik.errors.complement ? <p className='text-red-500 text-xs italic'>{formik.errors.complement}</p> : null}
                     </div>

                     <div>
                        <input
                           type="text"
                           name="number"
                           id="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                           p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Número da casa/apto"
                           value={formik.values.number}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                        />
                        {formik.touched.number && formik.errors.number ? <p className='text-red-500 text-xs italic'>{formik.errors.number}</p> : null}
                     </div>

                     <div>
                        <input
                           type="text"
                           name="phoneNumber"
                           id="phoneNumber"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                           p-1.5 sm:p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite seu telefone"
                           maxLength={14}
                           value={formatarTelefone(formik.values.phoneNumber)}
                           onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.replace(/\D/g, ''))}
                           onBlur={formik.handleBlur}
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? <p className='text-red-500 text-xs italic'>{formik.errors.phoneNumber}</p> : null}
                     </div>
                  </div>
               </div>

               <div className='flex justify-end py-5 mx-4'>
                  <button
                     className="cursor-pointer mx-auto bg-primary p-3 rounded-xl text-white font-semibold text-2xl"
                     type="submit"
                     disabled={loading}
                  >
                     {loading ? (
                        <div className='flex items-center justify-center'>
                           <Spinner /> Salvando
                        </div>
                     ) : 'Salvar e continuar'}
                  </button>
               </div>
            </form>
         </div>
      </>
   );
};

export default Adress;
