import Payment from 'payment';

export const valid = (prop) => {
   let isValid = true
   if (prop === undefined || prop === null) {
      isValid = false
   }
   return isValid
}

export const fomataRg = (a = '') => {
   a = a.replace(/\D/g, "");
   a = a.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
   return a;
}

export const formataData = (v = '') => {
   v = v.replace(/\D/g, "");
   v = v.replace(/(\d{2})(\d)/, "$1/$2")
   v = v.replace(/(\d{2})(\d)/, "$1/$2")
   return v;
}

export const formataCPF = (v = '') => {
   v=v.replace(/\D/g,"");
   v=v.replace(/(\d{3})(\d)/,"$1.$2");
   v=v.replace(/(\d{3})(\d)/,"$1.$2");
   v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
   return v;
} 
export const formatarTelefone = (v = '') => {
   v=v.replace(/\D/g,"");
   v=v.replace(/^(\d\d)(\d)/g,"($1)$2");
   v=v.replace(/(\d{5})(\d)/,"$1-$2");    
   return v;
}

export const isValidDate = (dateStr) => {
   // Verifica se o formato está correto
   const regex = /^\d{8}$/;
   if (!regex.test(dateStr)) return false;
 
   // Extrai dia, mês e ano
   const day = parseInt(dateStr.slice(0, 2), 10);
   const month = parseInt(dateStr.slice(2, 4), 10);
   const year = parseInt(dateStr.slice(4), 10);
 
   // Verifica se mês e ano estão dentro de limites válidos
   if (month < 1 || month > 12 || year < 1900 || year > 2100) return false;
 
   // Cria um objeto Date e verifica se é uma data válida
   const date = new Date(year, month - 1, day);
   return (
     date.getFullYear() === year &&
     date.getMonth() + 1 === month &&
     date.getDate() === day
   );
 };

export const formatarCep = (v = '') => {
   v = v.replace(/\D/g, "")
   v = v.replace(/^(\d{5})(\d)/, "$1-$2")
   return v;
}

export const findState = (estados, id = 10) => {
   let sta;
   estados.forEach(element => {
      if (element.id === id) sta = element.uf;
   });
   return sta
}

export const validateCPF = (cpf) => {
   // Remove caracteres não numéricos
   cpf = cpf.replace(/\D/g, '');

   // Verifica se o CPF tem 11 dígitos
   if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false;
   }

   // Valida o primeiro dígito verificador
   let sum = 0;
   for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
   }
   let remainder = (sum * 10) % 11;
   if (remainder === 10) remainder = 0;
   if (remainder !== parseInt(cpf.charAt(9))) return false;

   // Valida o segundo dígito verificador
   sum = 0;
   for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
   }
   remainder = (sum * 10) % 11;
   if (remainder === 10) remainder = 0;
   return remainder === parseInt(cpf.charAt(10));
};

export const setCity = (cities = [], id) => {
   let cti;
   cities.forEach(element => {
      if (element.id === id) cti = element.name
   });
   return cti
}

export const findFreight = (freight = [], id) => {
   let cti = {}
   freight.forEach(element => {
      if (element.city_id === id) cti = element
   });
   return cti
}

function clearNumber(value = '') {
   return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
   if (!value) {
      return value;
   }

   const issuer = Payment.fns.cardType(value);
   const clearValue = clearNumber(value);
   let nextValue;

   switch (issuer) {
      case 'amex':
         nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            10,
         )} ${clearValue.slice(10, 15)}`;
         break;
      case 'dinersclub':
         nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            10,
         )} ${clearValue.slice(10, 14)}`;
         break;
      default:
         nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            8,
         )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
         break;
   }
   return nextValue.trim();
}

export function formatCVC(value, allValues = {}) {
   const clearValue = clearNumber(value);
   let maxLength = 4;

   if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === 'amex' ? 4 : 3;
   }

   return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
   const clearValue = clearNumber(value);

   if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
   }

   return clearValue;
}

export function formatFormData(data) {
   return Object.keys(data).map(d => `${d}: ${data[d]}`);
}


export const brandsCard = {
   Mastercard: {
      uri: require('../assets/images/mastercard.png'),
      width: "60px",
      mobile: "35px"
   },
   Visa: {
      uri: require('../assets/images/visa.png'),
      width: "80px",
      mobile: "55px"
   },
   Amex: {
      uri: require('../assets/images/amex.png'),
      width: "40px",
      mobile: "35px"
   },
   Elo: {
      uri: require('../assets/images/elo.png'),
      width: "85px",
      mobile: "30px"
   },
   Pix: {
      uri: require('../assets/images/pix.png'),
      width: "95px",
      mobile: "30px"
   },
   Add: {
      uri: require('../assets/images/add.png'),
      width: "35px",
      mobile: "20px"
   }
}

export const models = [
   {
      image: require('../assets/images/bagG.png'),
      value: 3
   },
   {
      image: require('../assets/images/bagM.png'),
      value: 2
   },
   {
      image: require('../assets/images/bagP.png'),
      value: 1
   },
];

export function formatBag(bag) {

   let size_bag;

   switch (bag) {
      case 'Pequena':
         size_bag = 'P';
         break;
      case 'Média':
         size_bag = 'M';
         break;
      case 'Grande':
         size_bag = 'G';
         break;
      default:
         size_bag = 'A';
         break;
   }
   return size_bag;
}

export const states = [
   {
      "id": 1,
      "name": "Acre",
      "uf": "AC",
      "ibge": "12",
      "ddd": "68",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 2,
      "name": "Alagoas",
      "uf": "AL",
      "ibge": "27",
      "ddd": "82",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 3,
      "name": "Amazonas",
      "uf": "AM",
      "ibge": "13",
      "ddd": "97,92",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 4,
      "name": "Amapá",
      "uf": "AP",
      "ibge": "16",
      "ddd": "96",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 5,
      "name": "Bahia",
      "uf": "BA",
      "ibge": "29",
      "ddd": "77,75,73,74,71",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 6,
      "name": "Ceará",
      "uf": "CE",
      "ibge": "23",
      "ddd": "88,85",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 7,
      "name": "Distrito Federal",
      "uf": "DF",
      "ibge": "53",
      "ddd": "61",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 8,
      "name": "Espírito Santo",
      "uf": "ES",
      "ibge": "32",
      "ddd": "28,27",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 9,
      "name": "Goiás",
      "uf": "GO",
      "ibge": "52",
      "ddd": "62,64,61",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 10,
      "name": "Maranhão",
      "uf": "MA",
      "ibge": "21",
      "ddd": "99,98",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 11,
      "name": "Minas Gerais",
      "uf": "MG",
      "ibge": "31",
      "ddd": "34,37,31,33,35,38,32",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 12,
      "name": "Mato Grosso do Sul",
      "uf": "MS",
      "ibge": "50",
      "ddd": "67",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 13,
      "name": "Mato Grosso",
      "uf": "MT",
      "ibge": "51",
      "ddd": "65,66",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 14,
      "name": "Pará",
      "uf": "PA",
      "ibge": "15",
      "ddd": "91,94,93",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 15,
      "name": "Paraíba",
      "uf": "PB",
      "ibge": "25",
      "ddd": "83",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 16,
      "name": "Pernambuco",
      "uf": "PE",
      "ibge": "26",
      "ddd": "81,87",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 17,
      "name": "Piauí",
      "uf": "PI",
      "ibge": "22",
      "ddd": "89,86",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 18,
      "name": "Paraná",
      "uf": "PR",
      "ibge": "41",
      "ddd": "43,41,42,44,45,46",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 19,
      "name": "Rio de Janeiro",
      "uf": "RJ",
      "ibge": "33",
      "ddd": "24,22,21",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 20,
      "name": "Rio Grande do Norte",
      "uf": "RN",
      "ibge": "24",
      "ddd": "84",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 21,
      "name": "Rondônia",
      "uf": "RO",
      "ibge": "11",
      "ddd": "69",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 22,
      "name": "Roraima",
      "uf": "RR",
      "ibge": "14",
      "ddd": "95",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 23,
      "name": "Rio Grande do Sul",
      "uf": "RS",
      "ibge": "43",
      "ddd": "53,54,55,51",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 24,
      "name": "Santa Catarina",
      "uf": "SC",
      "ibge": "42",
      "ddd": "47,48,49",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 25,
      "name": "Sergipe",
      "uf": "SE",
      "ibge": "28",
      "ddd": "79",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 26,
      "name": "São Paulo",
      "uf": "SP",
      "ibge": "35",
      "ddd": "11,12,13,14,15,16,17,18,19",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   },
   {
      "id": 27,
      "name": "Tocantins",
      "uf": "TO",
      "ibge": "17",
      "ddd": "63",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
   }
]